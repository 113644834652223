import _, { map } from '../lib/underscore-esm-min';
var usMap;
var caMap;
var mxMap;
var locationsImagePrefix = "https://locations.andersonsinc.com";
var activeStates = [];
var USData;
var CAData;
var INTLData;
var USCities = [];
var CACities;
var INTLCities
var USStateCityList;
var CAProvinceCityList;
var IntlCountryCityList;
var locationList;
var stateList;
var serviceList;
var USServiceList;
var CAServiceList;
var INTLServiceList;

var activeUSStates;
var activeCAStates;

$(document).ready(function () {
	if (document.getElementById('vmap') || document.getElementById('vmap-ca')) {
		initializeData();
		//displayFacilities();
		//displayFilters();
		createMap();
		$('#secondary-navigation a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
			facilityRows();
		});

		//Clear filters and update dropdown values to default
		$('#clear-filters').click(function (e) {
			e.preventDefault();
			$('.facility').show();
			populateLocationFilter(null);
			populateServiceFilter(null);

			var tabValue = document.querySelector('.map-nav-items .nav-link.active').id;
			updateDropdownStateValue(tabValue);
			document.getElementById("cityDropdownMenu").innerHTML = "Select A City";
			document.getElementById("serviceDropdownMenu").innerHTML = "Select A Service";
			document.getElementById("serviceCityDropdownMenu").innerHTML = "Select A City";

		});

		$('#state-filters a, #other a').click(function (e) {
			e.preventDefault();
			let code = $(this).attr('data-state-filter');
			stateFilter(code);
		});

		$('#group-filters a').click(function (e) {
			e.preventDefault();
			code = $(this).attr('data-group-filter');
			groupFilter(code);
		});

		$('#service-filters a').click(function (e) {
			e.preventDefault();
			code = $(this).attr('data-service-filter');
			serviceFilter(code);
			fixClickableDrift();
		});

		//update dropdown based on country tab selected
		$('.map-nav-items button.nav-link').click(function (e) {
			$(".map-nav-items .nav-link[aria-selected='true']").attr('aria-selected', false);
			$(this).attr('aria-selected', true);
			var tabValue = $(this).attr("id");
			updateDropdownStateValue(tabValue);
			populateLocationFilter(null);
			populateServiceFilter(null);
			emptyMapDivs();
			createMap();

			if (tabValue == 'us-tab' || tabValue == undefined) {
				buildLocationsHTML(USData);
			}
			if (tabValue == 'ca-tab') {
				buildLocationsHTML(CAData);
			}
			if (tabValue == 'other-tab') {
				buildLocationsHTML(INTLData);
			}
		});

		$('.facility-summary').on('click', fixClickableDrift());

		$('.countries__map').on('click', function (e) {
			e.preventDefault()

			$('html, body').animate(
				{
					scrollTop: $($(this).attr('href')).offset().top,
				},
				50,
				'linear'
			)
		});

		facilityRows();

	}
});

$(document).on('click', '#LocationFilter_State a', function () {
	$('#clear-filters').click();
	var filterDisplayValue = (this).innerHTML;
	var filterValue = $(this).attr('data-state-filter')
	populateLocationFilter(filterValue);
	filterLocationsByState(filterValue);
	showDropdownValue("stateDropdownMenu", filterDisplayValue);

});

$(document).on('click', '#LocationFilter_City a', function () {
	var filterDisplayValue = (this).innerHTML;
	var filterValue = $(this).attr('data-city-filter')
	filterLocationsByCity(filterValue);
	showDropdownValue("cityDropdownMenu", filterDisplayValue);
});

$(document).on('click', '#ServiceFilter_Service a', function () {
	$('#clear-filters').click();
	var filterDisplayValue = (this).innerHTML;
	populateLocationFilter(null);
	var filterValue = $(this).attr('data-service-filter')
	populateServiceFilter(filterValue);
	filterLocationsByService(filterValue);
	showDropdownValue("serviceDropdownMenu", filterDisplayValue);
});

$(document).on('click', '#ServiceFilter_City a', function () {
	populateLocationFilter(null);
	var filterDisplayValue = (this).innerHTML;
	populateLocationFilter(null);
	var filterValue = $(this).attr('data-city-filter');
	filterLocationsByServiceCity(filterValue);
	showDropdownValue("serviceCityDropdownMenu", filterDisplayValue);
});

function fixClickableDrift() {
	$(window).scrollTop(0);
}

function shiftToFacilities() {

	$('html, body').animate({
		scrollTop: $("#facilities").offset().top + 500
	}, 50);
}

/**
 * updates dropdown country value based on active Panel and empty filter display value
 * @param {string} tabValue ID value of the active panel
 * @return {void} updates the value of statedropdown 
 */
function updateDropdownStateValue(tabValue) {
	//var country = $(".map-nav-items .nav-link.active").id;
	//var country = document.querySelector('.map-nav-items .nav-link.active').id;

	if (tabValue == 'us-tab' || tabValue == undefined) {
		document.getElementById("stateDropdownMenu").innerHTML = "Select A State";
	}
	if (tabValue == 'ca-tab') {
		document.getElementById("stateDropdownMenu").innerHTML = "Select A Province";
	}
	if (tabValue == 'other-tab') {
		document.getElementById("stateDropdownMenu").innerHTML = "Select A Country";
	}
	//update drop down values on tab change
	document.getElementById("cityDropdownMenu").innerHTML = "Select A City";
	document.getElementById("serviceCityDropdownMenu").innerHTML = "Select A City";
	document.getElementById("serviceDropdownMenu").innerHTML = "Select A Service";

}

function showDropdownValue(id, filterValue) {
	document.getElementById(id).innerHTML = filterValue;
}

function crossDomainAjax(url, successCallback) {

	// IE8 & 9 only Cross domain JSON GET request
	if ('XDomainRequest' in window && window.XDomainRequest !== null) {
		var xdr = new XDomainRequest(); // Use Microsoft XDR
		xdr.open('get', url);
		xdr.onload = function () {
			var dom = new ActiveXObject('Microsoft.XMLDOM'),
				JSON = $.parseJSON(xdr.responseText);

			dom.async = false;

			if (JSON == null || typeof (JSON) == 'undefined') {
				JSON = $.parseJSON(data.firstChild.textContent);
			}

			successCallback(JSON); // internal function
		};

		xdr.onerror = function () {
			_result = false;
		};
		xdr.send();
	}

	// IE7 and lower can't do cross domain
	else if (navigator.userAgent.indexOf('MSIE') != -1 &&
		parseInt(navigator.userAgent.match(/MSIE ([\d.]+)/)[1], 10) < 8) {
		return false;
	}

	// Do normal jQuery AJAX for everything else
	else {
		$.ajax({
			url: url,
			cache: false,
			dataType: 'json',
			type: 'GET',
			async: false, // must be set to false
			success: function (data, success) {
				successCallback(data);
			}
		});
	}
}

function initializeData() {
	let url = '/umbraco/api/locations/getalllocations';
	$.ajax({
		url: url,
		cache: false,
		dataType: 'json',
		type: 'GET',
		async: false, // must be set to false
		success: function (data, success) {
			USData = data.countryGroups[0];
			CAData = data.countryGroups[1];
			INTLData = data.countryGroups[2];

			//Puerto Rico Fix
			var PRData = convertUSStateToIntlLocation(USData, "Puerto Rico");
			INTLData.countries.push(PRData);
			USData.countries[0].states = _.without(USData.countries[0].states, _.findWhere(USData.countries[0].states, {
				stateName: "Puerto Rico"
			}));

			buildLocationsHTML(USData);
			USCities = getCities(USData);
			CACities = getCities(CAData);
			INTLCities = getCities(INTLData);

			activeUSStates = getActiveStates(USData)[0];
			activeCAStates = getActiveStates(CAData)[0];

			USStateCityList = getLocationFilter(USData);
			CAProvinceCityList = getLocationFilter(CAData);
			IntlCountryCityList = getLocationFilter(INTLData);

			serviceList = getServiceFilter(USData);
			USServiceList = getServiceFilter(USData);
			CAServiceList = getServiceFilter(CAData);
			INTLServiceList = getServiceFilter(INTLData);

			populateLocationFilter(null);
			populateServiceFilter(null);
			/*facilitySort();*/
		}
	});
}

//function facilitySort(){
//	$("#maumee-ohio").prependTo("#facilities");
//	$("#Maumee-Ohio").prepend("#facilities");
//}

function facilityRows() {
	$('.facility:visible').each(function (index) {
		if ((index + 1) % 2 == 0) {
			$(this).removeClass('odd');
			$(this).addClass('even');
		}
		else {
			$(this).removeClass('even');
			$(this).addClass('odd');
		}
	});
}

function convertUSStateToIntlLocation(data, stateFilter) {

	var filteredData = _.where(data.countries[0].states, { stateName: stateFilter });

	var convertedData = {
		countryCode: filteredData[0].stateCode, countryName: filteredData[0].stateName, highchartsMap: null, highchartsPlotByState: false,
		states: [{
			cityCount: 1, countryCode: filteredData[0].stateCode, countryGroupCode: "INT", stateCode: filteredData[0].stateCode, stateName: filteredData[0].stateName,
			cities: filteredData[0].cities
		}]
	};

	return convertedData;
}

function emptyMapDivs() {
	$("#vmap").html("");
	$("#vmap-ca").html("");
}

function createMap() {
	//usMap = new jvm.WorldMap({
	usMap = $('#vmap').vectorMap({
		map: 'us_mill_en',
		container: $('#vmap'),
		backgroundColor: 'transparent',
		zoomOnScroll: false,
		panOnDrag: true,
		setFocus: {
			scale: 100
		},
		regionStyle: {
			initial: {
				fill: '#f5e1b3',
				"fill-opacity": 1,
				stroke: 'white',
				"stroke-width": 3,
				"stroke-opacity": 0
			},
			hover: {
				"fill-opacity": 1
			},
			selected: {

			},
			selectedHover: {

			}
		},
		onRegionLabelShow: function (e, label, code) {
			label.css('opacity', 0);
		},
		onRegionClick: function (e, code) {
			if (usStateIsActive(code)) {
				filterLocationsByState(shortCode(code));
			}
		},
		onRegionOut: function (e, code) {
			if (usStateIsActive(code)) {
				getStateObject(code).attr('fill', '#fdb813');
			}
		}
	});

	caMap = $('#vmap-ca').vectorMap({
		map: 'ca_mill_en',
		container: $('#vmap-ca'),
		backgroundColor: 'transparent',
		zoomOnScroll: false,
		panOnDrag: true,
		setFocus: {
			scale: 100
		},
		regionStyle: {
			initial: {
				fill: '#f5e1b3',
				"fill-opacity": 1,
				stroke: 'white',
				"stroke-width": 3,
				"stroke-opacity": 0
			},
			hover: {
				"fill-opacity": 1
			},
			selected: {

			},
			selectedHover: {

			}
		},
		onRegionLabelShow: function (e, label, code) {
			label.css('opacity', 0);
		},
		onRegionClick: function (e, code) {
			if (caStateIsActive(code)) {
				filterLocationsByState(shortCode(code));
			}
		},
		onRegionOut: function (e, code) {
			if (caStateIsActive(code)) {
				getStateObject(code).attr('fill', '#fdb813');
			}
		}
	});

	activateStates();
}

function stateFilter(code) {
	$('.facility').hide();
	$('.facility[data-state="' + code + '"]').show();
	/*facilityRows();*/
}

function groupFilter(code) {
	$('.facility').hide();
	$('.facility[data-groups~="' + code + '"]').show();
	facilityRows();
}

function getStateObject(code) {
	return $('.jvectormap-region[data-code="' + code + '"]');
}

function shortCode(code) {
	var split = code.split('-');
	return split[1];
}

function activateStates() {
	_.each(activeUSStates, function (state) {
		$('.jvectormap-region[data-code="US-' + state.StateCode + '"]').attr('data-active', 'true');
	});

	_.each(activeCAStates, function (state) {
		$('.jvectormap-region[data-code="CA-' + state.StateCode + '"]').attr('data-active', 'true');
	});
}

function usStateIsActive(code) {
	code = shortCode(code);
	for (var i in activeUSStates) {
		if (activeUSStates[i].StateCode == code) {
			return true;
		}
	}
	return false;
}

function caStateIsActive(code) {
	code = shortCode(code);
	for (var i in activeCAStates) {
		if (activeCAStates[i].StateCode == code) {
			return true;
		}
	}
	return false;
}

//return cities list
function getCities(data) {
	var cities = [];
	if (data.countries.length <= 1) {
		cities.push(_.map(data.countries[0].states, function (item) {
			var city = _.pluck(item.cities, 'cityStateName');
			return city;
		}));
	}
	else if (data.countries.length > 1) {
		_.each(data.countries, function (item) {
			cities.push(_.map(item.states, function (state) {
				var city = _.pluck(state.cities, 'cityStateName');
				return city;
			}));

		});
	}
	return cities;
}

function getActiveStates(data) {
	var result = [];
	result.push(_.map(data.countries[0].states, function (item) {
		var obj = {
			StateName: item.stateName,
			StateCode: item.stateCode,
		};
		return obj;

	}));
	return result;
}

function getLocationFilter(data) {
	var result = [];
	if (data.countries.length <= 1) {
		result.push(_.map(data.countries[0].states, function (item) {
			var cities = _.pluck(item.cities, 'cityStateName');
			var obj = {
				StateName: item.stateName,
				StateCode: item.stateCode,
				Cities: cities
			};
			return obj;

		}));
	}
	else if (data.countries.length > 1) {
		var dataset = [];
		_.each(data.countries, function (country) {
			dataset.push(_.map(country.states, function (state) {
				var cities = _.pluck(state.cities, 'cityStateName');
				var obj = {
					StateName: state.stateName,
					StateCode: state.stateCode,
					Cities: cities
				};
				return obj;
			}));
		});

		_.each(dataset, function (item) {
			_.each(item, function (obj) {
				result.push(obj);
			});
		});
	}
	return result;
};

function getServiceFilter(data) {
	var result = [];
	_.each(data.countries, function (country) {
		_.each(country.states, function (state) {
			_.each(state.cities, function (city) {
				var service = _.pluck(city.services, 'serviceName');
				var obj = {
					CityName: city.cityStateName,
					Service: service
				};
				result.push(obj);
			});
		});
	});
	return result;
};

function populateLocationFilter(filterValue) {
	var country = document.querySelector('.map-nav-items .nav-link.active').id;
	var data = USStateCityList[0];
	if (country == 'ca-tab') {
		data = CAProvinceCityList[0];
	}
	else if (country == 'other-tab') {
		data = IntlCountryCityList;
	}
	if (filterValue == null || filterValue == '') {
		var stateOptionsHtml = '';
		var cityOptionsHtml = '';
		_.each(data, function (state) {
			stateOptionsHtml += '<li><a href="javascript:void(0)" data-state-filter="' + state.StateCode + '">' + state.StateName + '</a></li>';
			_.each(state.Cities, function (city) {
				cityOptionsHtml += '<li><a href="javascript:void(0)" data-city-filter="' + city + '">' + city + '</a></li>';
			});

		});
		$("#LocationFilter_State").html(stateOptionsHtml);
		$("#LocationFilter_City").html(cityOptionsHtml);
	}
	else {
		var cityOptionsHtml = '';
		_.each(data, function (state) {
			if (state.StateCode == filterValue) {
				_.each(state.Cities, function (city) {
					var cityName = city.replace(', ' + state.StateName, '');
					cityOptionsHtml += '<li><a href="javascript:void(0)" data-city-filter="' + city + '">' + cityName + '</a></li>';
				});
			}
		});
		$("#LocationFilter_City").html(cityOptionsHtml);
	}
};

function populateServiceFilter(filterValue) {
	var country = document.querySelector('.map-nav-items .nav-link.active').id;
	var servicedata = USServiceList;
	if (country == 'ca-tab') {
		servicedata = CAServiceList;
	}
	else if (country == 'other-tab') {
		servicedata = INTLServiceList;
	}
	if (filterValue == null || filterValue == "") {
		var servicesList = [];
		var serviceOptionsHtml = '';
		var cityOptionsHtml = '';
		_.each(servicedata, function (serviceObj) {
			_.each(serviceObj.Service, function (service) {
				var serviceName = service;
				if (!_.contains(servicesList, serviceName)) {
					servicesList.push(serviceName);
					serviceOptionsHtml += '<li><a href="javascript:void(0)" data-service-filter="' + serviceName + '">' + serviceName + '</a></li>';
				}
			});
		});
		_.each(servicedata, function (serviceCity) {
			var city = serviceCity.CityName;
			cityOptionsHtml += '<li><a href="javascript:void(0)" data-city-filter="' + city + '">' + city + '</a></li>';
		});
		$("#ServiceFilter_Service").html(serviceOptionsHtml);
		$("#ServiceFilter_City").html(cityOptionsHtml);
	}
	else {
		var cityOptionsHtml = '';
		var cityData = [];
		_.each(servicedata, function (serviceObj) {
			var services = serviceObj.Service;
			var available = _.contains(services, filterValue);
			if (available) {
				cityData.push(serviceObj);
			}
		});
		_.each(cityData, function (data) {
			cityOptionsHtml += '<li><a href="javascript:void(0)" data-city-filter="' + data.CityName + '">' + data.CityName + '</a></li>';
		});
		$("#ServiceFilter_City").html(cityOptionsHtml);
	}
};

$("#LocationFilter_City").change(function () {
	var filterValue = $(this).val();
	filterLocationsByCity(filterValue);
});

function buildLocationsHTML(data) {
	var LocationsHTML = '';
	_.each(data.countries, function (country) {
		_.each(country.states, function (state) {
			let stateCode = state.stateCode;
			let stateName = state.stateName;
			_.each(state.cities, function (city) {
				let cityName = city.cityStateName;
				let cityNameId = cityName.replace(', ', '-');
				var locationImageURL = locationsImagePrefix + city.imagePath;
				var randomPanelId = "facility-" + Math.floor(Math.random() * 1000);

				var cityServicesListHTML = '';
				var serviceLocationHTML = '';
				var cityServicesList = [];
				_.each(city.services, function (service) {
					cityServicesListHTML += '<li>' + service.serviceName + '</li>';
					cityServicesList.push(service.serviceName);
					_.each(service.locations, function (serviceLocation) {
						console.log(serviceLocation);
						var addressLine2 = serviceLocation.cityStateName;
						var zip = serviceLocation.postalCode;
						var randomGroupId = "group-" + Math.floor(Math.random() * 1000);
						serviceLocationHTML +=
							//#region Location Div Group
							'<div id="' + randomGroupId + '">' +
							'<div class="location">' +
							'<h3>' + serviceLocation.locationName + '</h3>' +
							'<div>' +
							'<b>Address:</b> ' + serviceLocation.address1 + ',<br>' +
							 addressLine2 + ',  ' + serviceLocation.postalCode + '<br>' +
							'<b>' + serviceLocation.phone1Label + ':</b> ' + serviceLocation.phone1Value + '<br>' +
							'</div >' +
							'</div >' +
							'</div >';
						//#endregion
					});
				});

				LocationsHTML +=
					'<div id="' + cityNameId + '" class="facility panel panel-defaut odd" data-city="' + cityName + '"   data-state="' + stateCode + '" data-facilties="' + cityServicesList.join(' ') + '" >' +
					//#region Facility Summary Section
					'<div class="row facility-summary collapsed" href="#' + randomPanelId + '" data-bs-toggle="collapse" data-parent="#facilities" aria-expanded="false" aria-controls="' + randomPanelId + '">' +
					'<div class="col-md-3 facility-toggle d-none d-md-block">' +
					'<div class="ss-icon ss-gizmo">+</div>' +
					'</div>' +
					'<div class="col-md-9 facility-overview col-sm-12">' +
					'<h2>' + cityName + '</h2>' +
					'<ul>' +
					cityServicesListHTML +
					'</ul>' +
					'</div>' +
					'</div>' +
					//#endregion
					//#region Facility Details Section
					'<div id="' + randomPanelId + '" class="row facility-details panel-collapse collapse" role="tabpanel" style="">' +
					'<div class="col-md-3 d-sm-none d-md-block location-map">' +
					'<img class="img-responsive full-width" src="' + locationImageURL + '">' +
					'</div>' +
					'<div class="col-sm-8 col-md-9 tab-content facility-content">' +
					serviceLocationHTML +
					'</div>' +

					'</div>' +
					//#endregion
					'</div>'

			});
		});

	});

	$("#facilities").html(LocationsHTML);
}

function filterLocationsByState(value) {
	if (value != "") {
		$('.facility').hide();
		$('.facility[data-state="' + value + '"]').show();
	}
	else {
		$('.facility').show();
	}
}

function filterLocationsByCity(value) {
	if (value != "") {
		$('.facility').hide();
		$('.facility[data-city*="' + value + '"]').show();
	}
	else {
		var stateVal = $("#LocationFilter_State").val();
		filterLocationsByState(stateVal);
	}
}

function filterLocationsByService(value) {
	if (value != "") {
		$('.facility').hide();
		if (value.toLowerCase() == 'office') {
			$('.facility[data-facilties^="' + value + '"]').show();

		} else {
			$('.facility[data-facilties*="' + value + '"]').show();

		}
	}
	else {
		$('.facility').show();
	}
}

function filterLocationsByServiceCity(value) {
	if (value != "") {
		$('.facility').hide();
		$('.facility[data-city*="' + value + '"]').show();
	}
	else {
		$('.facility').show();
	}
}
